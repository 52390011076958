body {
  margin: 0;
  height: 100vh;
}

* {
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.item-gap {
  max-width: 588px;
  min-width: 275px;
  width: max-content !important;
  margin: 20px 0 20px 20px;
}

.container-mobile {
  padding-bottom: 40px;
}

.container-desktop {
  padding-bottom: 45px !important;
}

.dot-list-desktop {
  padding-bottom: 10px !important;
}

.react-multiple-carousel__arrow {
  bottom: 0;
}

.react-multiple-carousel__arrow--right {
  right: 40% !important;
}

.react-multiple-carousel__arrow--left {
  left: 40% !important;
}

.MuiTextField-root textarea {
  font-size: 16px;
  line-height: 20px;
}

.MuiInputBase-root .MuiSvgIcon-root {
  width: 2em;
}

.textarea-root,
.MuiInputBase-root {
  border-radius: 8px !important;
}

.hover-link {
  color: #383838; 
  text-underline-offset: 4px; 
  text-decoration-thickness: 1.5px; 
  text-decoration-color: #383838;
}

.hover-link:hover {
    color: #F25832;
    text-decoration-color: #F25832;
}

.hover-link:clicked {
  color: #F25832;
  text-decoration-color: #F25832;
}

blockquote::before {
  content: open-quote;
}
blockquote::after {
  content: close-quote;
}
blockquote {
  font-family: "Source Code Pro";
  quotes: "“" "”" "‘" "’";
   margin: 1px !important;
}
